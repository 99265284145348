.root 
    position: fixed
    z-index: 110

    font-family: "Gotham Rounded A", "Gotham Rounded B"
    font-style: normal
    font-weight: normal
    font-size: 20px
    line-height: 28px
    letter-spacing: 0.025em
    color: #2c302c
    
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    pointer-events: all

    *
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol
        margin: 0

    h1,
    h2
        font-style: normal
        font-weight: 500
        text-transform: uppercase  

    h3
        font-family: "Exo 2"
        font-weight: 500
        font-size: 1.4em
        line-height: 1.28em

    ul,
    ol
        padding: 0
        list-style: none

    a
        color: #2c302c
        text-decoration: none

    \:global
        .center.main
            max-width: 1440px
            padding: 0 57px

            color: #fff
            font-size: 1rem
            line-height: 1

        .center.main > .inner
            position: relative

        .rich
            font-size: 1em
            line-height: 1.4em

            * + h2,
            * + h3,
            * + p,
            * + ul,
            * + ol 
                margin-top: 1.05em

            ul 
                list-style-type: disc

                li 
                    margin-left: 1em

                    + li 
                        margin-top: 0.55em

            ol 
                li
                    counter-increment: count
                    position: relative
                    padding-left: 1.25em 

                    &::before
                        position: absolute
                        left: 0
                        top: 0
                        content: counter(count) ")"
                
                    + li 
                        margin-top: 1.05em

            a
                color: #E7237D

                &:hover
                    text-decoration: underline
    
    &::before
       position: absolute
       left: 0
       top: 0
       width: 100%
       height: 100%
       background: rgba(0,0,0,0.6)
       content: "" 

    > div
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        background: black
        padding-top: 20px
        padding-bottom: 20px
        overflow: auto
        max-height: 400px

    \:global
        h6
            font-size: 2rem
            margin-bottom: 1rem

        button
            height: 41px
            padding: 0 10px
            border-radius: 5px
            border: none
            background-color: #fff
            color: #000
            font-family: "Gotham Rounded A", "Gotham Rounded B"
            cursor: pointer

            &.save-all
                background-color: #E8247E
                color: #fff

        .action
            display: inline-block
            color: #E8247E
            cursor: pointer
   
        .intro
            display: flex
            flex-direction: column

            @at-root:global(.overview)#{&}
                @media screen and ( min-width: 768px )
                    flex-direction: row

            > div + div
                @at-root:global(.overview)#{&}
                    @media screen and ( min-width: 768px )
                        flex: 0 0 200px
                        margin-left: 20px

                    @media screen and ( max-width: 767px )
                        margin-top: 20px

                    button 
                        width: 100%

                        + button
                            margin-top: 20px

                @at-root:global(.settings)#{&}
                    display: flex
                    margin-top: 20px

                    button + button
                        margin-left: 20px

               

            .back       
                margin-right: 0
                margin-left: auto

            .types
                margin-top: 20px

        .links
            margin-top: 15px
            font-size: 0.75rem
            text-align: center

            > * 
                margin: 5px

            > a 
                color: #bdc1c8

        .settings
            margin-top: 20px

            .type
                position: relative
                font-size: 0.75rem
                line-height: 1.5

                + .type
                    margin-top: 20px


                .title
                    display: block
                    margin-bottom: 10px
                    font-size: 1.25rem

                > .action
                    margin-top: 10px

                .switch
                    position: absolute
                    right: 0
                    top: 0
                    width: 50px
                    height: 26px
                    background-color: #bdc1c8
                    border-radius: 13px
                    cursor: pointer

                    > span
                        position: absolute
                        right: calc(100% + 10px)
                        top: 0
                        line-height: 26px
                        color: #fff

                    &::before
                        position: absolute
                        left: 3px
                        top: 3px
                        display: block
                        width: 20px
                        height: 20px
                        background: #fff
                        border-radius: 100%
                        content: ''

                    &.active
                        &::before
                            left: 27px
                            background-color: #E8247E
                        
                .cookie-type
                    margin-top: 10px
                   

                    > div
                        display: flex

                        + div
                            margin-top: 10px

                        > div
                            flex: 0 0 40%

                            + div
                                flex: 0 0 60%

                            a
                               color: #E8247E 